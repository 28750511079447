import axios from "axios";
import router from "@clients/router";

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.httpsAgent = true;
axios.defaults.validateStatus = status => status >= 200 && status < 300;

const authApi = axios.create({
  baseURL: import.meta.env.VITE_APP_AUTH_SERVICE,
});
const clientsApi = axios.create({
  baseURL: import.meta.env.VITE_APP_CLIENTS_API,
});
clientsApi.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      const errorMessage = error.response.data;

      if (errorMessage == "vpn_required") {
        router.push({ name: "VPNRequired" });
      }

      if (error.response?.status == 401) {
        switch (errorMessage) {
          case "2fa_required":
            window.location.replace(
              `${import.meta.env.VITE_APP_ACCOUNT_DOMAIN}/two-factor?redirect=${encodeURIComponent(window.location.href)}`
            );
            break;
          case "no_user_login":
            router.push("invalid-user");
            break;
          default:
            window.location.replace(
              `${import.meta.env.VITE_APP_ACCOUNT_DOMAIN}/login?redirect=${encodeURIComponent(window.location.href)}`
            );
            break;
        }
      }
    }

    return Promise.reject(error);
  }
);
const meetApi = axios.create({
  baseURL: import.meta.env.VITE_APP_MEET_API_URL,
});

export { authApi, clientsApi, meetApi };

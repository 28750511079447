import App from "@clients/App.vue";
import "@clients/composables/useReviewUpdates";
import "@clients/index.css";
import "@clients/lib/axios";
import "@clients/lib/flagsmith";
import "@clients/lib/hubspotChat.ts";
import "@clients/lib/mixpanel.ts";
import router from "@clients/router";
import * as Sentry from "@sentry/vue";
import vClickOutside from "click-outside-vue3";
import { createApp } from "vue";
import { createVuetify } from "vuetify";

const app = createApp(App);

const environment = import.meta.env.VITE_APP_SENTRY_ENV;
Sentry.init({
  app,
  dsn: "https://ec554468ca7b478ab7fc31e4c685b2d1@o424465.ingest.sentry.io/6231936",
  release: import.meta.env.VITE_APP_VERSION,
  integrations: [Sentry.browserTracingIntegration({ router })],
  environment: environment,
  tracesSampleRate: environment === "live" ? 1.0 : 0.1,
  enabled: environment === "live" || environment === "test",
});

const vuetify = createVuetify();

app.use(router);
app.use(vClickOutside);
app.use(vuetify);
app.mount("#app");
